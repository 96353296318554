import {css, Global} from '@emotion/react';
import {ConfigProvider} from 'antd';
import {ReactNode, useState} from 'react';
import {createContext, useContext} from 'use-context-selector';
import {getThemeColors} from './appColors';
import {getTheme} from './themeConfig';

export type AppThemeModeType = 'light';
export type AppThemeType = 'housewarePink';
interface AppThemeContext {
  appThemeMode: AppThemeModeType;
  setAppThemeMode: (themeMode: AppThemeModeType) => void;
  appTheme: AppThemeType;
  setAppTheme: (theme: AppThemeType) => void;
  themeColors: any;
}
const ThemeContext = createContext<AppThemeContext | undefined>(undefined);

export default function AppThemeProvider({children}: {children: ReactNode}) {
  const [selectedThemeMode, setSelectedThemeMode] = useState<AppThemeModeType>(() => {
    if (typeof window === 'undefined') return 'light';
    else {
      return (localStorage?.getItem('appThemeMode') as AppThemeModeType) || 'light';
    }
  });
  const [selectedTheme, setSelectedTheme] = useState<AppThemeType>(() => {
    if (typeof window === 'undefined') return 'housewarePink';
    else {
      return (localStorage?.getItem('appTheme') as AppThemeType) || 'housewarePink';
    }
  });

  const setAppThemeMode = (appThemeMode: AppThemeModeType) => {
    setSelectedThemeMode(appThemeMode);
  };

  const setAppTheme = (appTheme: AppThemeType) => {
    setSelectedTheme(appTheme);
  };

  const antdTheme = getTheme(selectedThemeMode, selectedTheme);
  const themeColors = getThemeColors({
    mode: selectedThemeMode,
    theme: selectedTheme,
  });

  const globalTheme = Object.entries(themeColors)
    .map(
      ([key, value]) => `
        ${key}: ${value};
      `
    )
    ?.join('');

  return (
    <ThemeContext.Provider
      value={{
        appThemeMode: selectedThemeMode,
        setAppThemeMode,
        appTheme: selectedTheme,
        setAppTheme,
        themeColors,
      }}
    >
      <ConfigProvider theme={antdTheme}>
        <Global
          styles={css`
            :root {
              ${globalTheme}
            }
          `}
        />
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
}

export const useAppTheme = (): AppThemeContext => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
