import config from '../config';
import $fetch from '../helpers/$fetch';

export async function identifyKnockUserAPI(payload) {
  const res = await $fetch(`${config.apiHostURL}/organization/knock/identify`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  if (res.ok) {
    return res.json();
  }
  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getEventsAPI() {
  const res = await $fetch(
    `${config.apiHostURL}/organization/product-analytics/metadata`
  );
  if (res.ok) {
    const data = await res.json();
    return data;
  }
  throw Error(res.statusText || res.status || 'Something went wrong');
}

export async function getGoalsAPI() {
  return $fetch(`${config.apiHostURL}/organization/metric/goal`);
}

export async function createGoalAPI(goal) {
  const res = await $fetch(`${config.apiHostURL}/organization/metric/goal`, {
    method: 'POST',
    body: JSON.stringify(goal),
  });
  if (res.status === 201) return true;
  throw Error(res.statusText || 'Something went wrong');
}

export async function fetchTabularDataAPI(payload) {
  const url = `${config.apiHostURL}/organization/tabular-data/`;
  const res = await $fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  const responseParsed = await res.json();
  return responseParsed.data;
}
