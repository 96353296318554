import React, {createContext, useContext, useState, useRef, ReactNode} from 'react';
import {ReactourStep} from 'reactour';
type Props = {
  children: ReactNode;
};
type TourParams = {
  isTourActive: boolean;
  setIsTourActive: React.Dispatch<React.SetStateAction<boolean>>;
  tourRef: React.MutableRefObject<any>;
  tourStep: number;
  nextStep: () => void;
  goToStep: (step: number) => void;
  startTour: () => void;
  setTourStep: React.Dispatch<React.SetStateAction<number>>;
  tourSteps: TourComponentProps[] | null;
  setTourSteps: React.Dispatch<React.SetStateAction<TourComponentProps[] | null>>;
  isSinglePageTour: boolean;
  setIsSinglePageTour: React.Dispatch<React.SetStateAction<boolean>>;
};
type TourComponentProps = ReactourStep & {route?: string; selectorId?: string};

const TourContext = createContext<TourParams>(null!);

export const useTour = () => useContext(TourContext);

export const TourProvider = ({children}: Props) => {
  const [isTourActive, setIsTourActive] = useState(false);
  const [tourSteps, setTourSteps] = useState<TourComponentProps[] | null>(null);
  const [tourStep, setTourStep] = useState(0);
  const [isSinglePageTour, setIsSinglePageTour] = useState(true);

  const tourRef = useRef<any>(null);

  const startTour = () => {
    setIsTourActive(true);
  };

  const nextStep = () => {
    setTourStep((prev) => prev + 1);
  };

  const goToStep = (step: number) => {
    setTourStep(step);
  };

  const contextValue = {
    isTourActive,
    setIsTourActive,
    tourStep,
    nextStep,
    goToStep,
    tourRef,
    startTour,
    setTourStep,
    tourSteps,
    setTourSteps,
    isSinglePageTour,
    setIsSinglePageTour,
  };

  return <TourContext.Provider value={contextValue}>{children}</TourContext.Provider>;
};
