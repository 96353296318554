import {Navigate} from 'react-router-dom';
import useParam from '../../hooks/useParam';
import useAppStoreSelector from '../../store/useAppStoreSelector';
import PageLoader from '../PageLoader';

// component to login user with jwt from url param and redirect to url from param

export default function DomainRedirect() {
  const state = useAppStoreSelector((s) => s.state);
  const redirectUrl = useParam('url');
  // redirection from /login sets redirection url in state param
  const redirectState = useParam('state');
  const token = useParam('hswrToken');

  if (!token) return <Navigate to={redirectState || redirectUrl || '/'} />;

  if (state.user) {
    return <Navigate to={redirectState || redirectUrl || '/'} />;
  }

  return <PageLoader style={{width: '100vw', height: '100vh'}} />;
}
