import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import DomainRedirect from '../components/Auth/DomainRedirect';

import PageLoader from '../components/PageLoader';

const Metrics = React.lazy(() => import('../components/Metrics/Metrics'));
const TemplateRouter = React.lazy(() =>
  import('../components/Templates/TemplatesRouter')
);
const SlackRedirect = React.lazy(() => import('../components/Auth/SlackRedirect'));

const Authenticate = React.lazy(() => import('../components/Auth/Authenticate'));
const AuthenticateCallback = React.lazy(() => import('../components/Auth/AuthCallback'));

export const publicRoutes = [
  {
    route: '/templates',
    component: <TemplateRouter />,
  },
  {
    route: '/metrics?active=metrics-hub',
    component: <Metrics />,
  },
];

export default function NonAuthenticatedRoutes() {
  return (
    <Routes>
      {publicRoutes.map((r) => (
        <Route key={r.route} path={r.route} element={r.component} />
      ))}
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route path="/domain_redirect" element={<DomainRedirect />} />
      <Route
        path="/wait-redirecting"
        element={
          <PageLoader
            style={{width: '100vw', height: '100vh'}}
            loadingText="Please hold tight! You are being redirected to your customised Houseware URL"
          />
        }
      />
      <Route path="/slack_redirect" element={<SlackRedirect />} />
      <Route path="*" element={<Authenticate />} />
    </Routes>
  );
}
