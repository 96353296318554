import {ReactNode} from 'react';
import {createContext} from 'use-context-selector';
import {AppState, BlockState} from './types';
import useAppReducer, {AppDispatch} from './useAppReducer';
import useAppServices from './useAppServices';
import useBlockReducer, {BlockDispatch} from './useBlockReducer';
import useBlockServices from './useBlockSerivces';
import useUserServices from './useUserServices';

export type AppStore = ReturnType<typeof useUserServices> &
  ReturnType<typeof useAppServices> & {
    state: AppState;
    dispatch: AppDispatch;
  };

export const StoreContext = createContext<AppStore>(null!);

type Props = {
  children: ReactNode;
};

export function StoreProvider({children}: Props) {
  const [state, dispatch] = useAppReducer();
  const userServices = useUserServices(dispatch, state);
  const appServies = useAppServices(dispatch);

  return (
    <StoreContext.Provider value={{state, dispatch, ...userServices, ...appServies}}>
      {children}
    </StoreContext.Provider>
  );
}

export type BlockStore = ReturnType<typeof useBlockServices> & {
  state: BlockState;
  dispatch: BlockDispatch;
};

export const BlockContext = createContext<BlockStore>(null!);

export function BlockStoreProvider({children}: Props) {
  const [state, dispatch] = useBlockReducer();
  const blockServices = useBlockServices(dispatch);

  return (
    <BlockContext.Provider value={{state, dispatch, ...blockServices}}>
      {children}
    </BlockContext.Provider>
  );
}
