const apiHostURL = process.env.REACT_APP_API_HOST || '';
const apiHostURLV2 = process.env.REACT_APP_API_HOST_V2 || '';
const isFiDeploy = apiHostURL.includes('fi') || apiHostURL.includes('poc');
const isProductionApp = apiHostURL.includes('production') || isFiDeploy;
const onboardingTemplates = [
  {
    title: 'Real Time Support Status',
    appSlug: isProductionApp
      ? '/templates/20fbfc7e-7e13-4ef0-8566-045d05360764'
      : '/templates/ba671ed8-981f-411e-9e8e-84126f80292f',
    cardImage: '/assets/tiles/product_0_4.png',
  },
  {
    title: 'User activity tracker',
    appSlug: isProductionApp
      ? '/templates/f752bb57-ccdc-4af5-8d39-b025453ed6b2'
      : '/templates/8638b754-f283-4356-aa90-7d23b5d55869',
    cardImage: '/assets/tiles/product_1_2.jpg',
  },
  {
    title: 'Performance Marketing',
    appSlug: isProductionApp
      ? '/templates/6454366f-04b1-4734-a6df-3c2a2459703b'
      : '/templates/8638b754-f283-4356-aa90-7d23b5d55869',
    cardImage: '/assets/tiles/product_2_0.png',
  },
];

const demoUserIds = [
  'cus_c6477c72370044d3844eb8205716f4e3',
  'cus_e8148d730c2d436f9dc11f6651cbd8ae',
  'cus_181f893ed132406dbc44b94619f02592',
];

const config = {
  isFiDeploy,
  isProductionApp,
  apiHostURL,
  apiHostURLV2,
  googleAuthClientId:
    '1065482580621-2plp9sagfk0e2v02e1bq7hibrga4fu0f.apps.googleusercontent.com',
  slackRedirectURI:
    process.env.NODE_ENV === 'development'
      ? `${window.location.origin}/slack_redirect`
      : `${process.env.REACT_APP_AUTH_REDIRECT_DOMAIN}/slack_redirect`,
  commandBarApiKey: process.env.REACT_APP_COMMAND_BAR_API_KEY,
  loomSdkApiKey: process.env.REACT_APP_LOOM_SDK_API_KEY,
  airtableSecureDataSharingApiKey:
    process.env.REACT_APP_AIRTABLE_SECURE_DATA_SHARING_API_KEY,
  logRocketKey: isProductionApp ? 'yhs54x/houseware-app' : 'yhs54x/houseware-develop',
  sentryDsnKey: isProductionApp
    ? 'https://f88f35441e96414cbff171c7b104f18a@o1155050.ingest.sentry.io/6235240'
    : '',
  environment: isProductionApp ? 'production' : 'development',
  authRedirectURL:
    process.env.NODE_ENV === 'development'
      ? `${window.location.origin}`
      : process.env.REACT_APP_AUTH_REDIRECT_DOMAIN,
  launchDarklyKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY || '63806fc0ccbc38119aa8b186',
  templates: onboardingTemplates,
  demoCustomerWorkspaceID: 'a7a7694a-a3b7-4869-9748-62550fd0f93d',
  reactQueryOfflineCacheKey: 'REACT_QUERY_OFFLINE_CACHE_V3',
  knockPublicKey: process.env.REACT_APP_KNOCK_PUBLIC_API_KEY,
  knockFeedChannelId: process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID,
  localhost: ['localhost', '127.0.0.1', '192.168.1.101'],
  // replace empty string with the id for prod (both prod & dev runs on same id) to enable liveblocks on dev
  liveBlocksId: isProductionApp ? 'pk_live_4Q_yyu38Er8LH2Ei9ZH3YQxe' : '',
  demoUserIds,
  defaultDateFilterDays: window.location.origin.includes('launchdarkly') ? 30 : 3,
  defaultTimeFilterWindow: {
    unit: 'day',
    value: window.location.origin.includes('launchdarkly') ? 30 : 3,
  },
};

export default config;
