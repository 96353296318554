import {Avatar, AvatarProps, Tooltip} from 'antd';
import {useMemo} from 'react';
import {OrgUsers} from '../../store/types';
import useAppStoreSelector from '../../store/useAppStoreSelector';

type AvatarVariant = 'primary' | 'ghost' | 'light' | 'secondary';

type Props = {
  avatarProps?: AvatarProps;
  variant?: AvatarVariant;
  disableTooltip?: boolean;
  tooltipTitle?: React.ReactNode;
};

type PropsWithUserId = Props & {
  userId: number;
};

type PropsWithEmail = Props & {
  email: string;
};

export const HOUSEWARE_USER_AVATAR_COLOR_PRIMARY = '#f56a00' as const;
export const HOUSEWARE_USER_AVATAR_COLOR_GHOST = 'rgb(255 207 171)' as const;

const userIdDetailsMap: Record<number | string, OrgUsers[number]> = {};

export default function HousewareUserAvatar(props: PropsWithEmail): JSX.Element;
export default function HousewareUserAvatar(props: PropsWithUserId): JSX.Element;
export default function HousewareUserAvatar({
  userId,
  email,
  avatarProps = {},
  disableTooltip = false,
  tooltipTitle,
}: Partial<PropsWithUserId & PropsWithEmail>) {
  const orgUsers = useAppStoreSelector((s) => s.state?.orgUsers);

  const identfier = (email || userId) as string | number;

  const userDetails = useMemo(() => {
    if (orgUsers && !userIdDetailsMap[identfier]) {
      orgUsers.forEach((user) => {
        userIdDetailsMap[user.id] = user;
        userIdDetailsMap[user.email] = user;
      });
    }
    return userIdDetailsMap[identfier];
  }, [orgUsers, identfier]);

  const userName = userDetails?.name || ' ';

  const userAvatar = useMemo(() => {
    if (userDetails?.profile_picture_url) {
      return (
        <Avatar
          {...avatarProps}
          style={{
            color: '#fff',
            backgroundColor: 'var(--primary-60)',
            ...(avatarProps.style || {}),
          }}
          src={userDetails.profile_picture_url}
        />
      );
    }

    return (
      <Avatar
        {...avatarProps}
        style={{
          color: '#fff',
          backgroundColor: 'var(--primary-60)',
          ...(avatarProps.style || {}),
        }}
      >
        {userName[0].toUpperCase()}
      </Avatar>
    );
  }, [avatarProps, userDetails?.profile_picture_url, userName]);

  if (disableTooltip) return <>{userAvatar}</>;

  return <Tooltip title={tooltipTitle || userName}>{userAvatar}</Tooltip>;
}
