import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

const SelectOrg = React.lazy(() => import('../components/Auth/SelectOrg'));

const ConnectWarehouseRoutes = React.lazy(() =>
  import('../components/Connections/ConnectWarehouse')
);

export default function WarehouseNotConnectedRoutes() {
  return (
    <Routes>
      <Route path="/select-org" element={<SelectOrg />} />
      <Route path="/connect-warehouse/*" element={<ConnectWarehouseRoutes />} />
      <Route path="*" element={<Navigate to="/connect-warehouse" replace />} />
    </Routes>
  );
}
