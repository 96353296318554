import {KnockFeedProvider} from '@knocklabs/react-notification-feed';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import AllRoutes from './appRoutes/AllRoutes';
import {
  default as NonAuthenticatedRoutes,
  publicRoutes,
} from './appRoutes/NonAuthenticatedRoutes';
import WarehouseNotConnectedRoutes from './appRoutes/WarehouseNotConnectedRoutes';
import {NotificationToast} from './components/HousewareApp/components/Notification/Notification';
import PageLoader from './components/PageLoader';
import Error500 from './components/common/Error/Error500';
import config from './config';
import Analytics from './services/analytics/Analytics';
import {appEventsPA} from './services/analytics/appEvents';
import useAppStoreSelector from './store/useAppStoreSelector';
const TourComponent = React.lazy(() => import('./components/ProductTour/TourCompomnent'));
const AssetPreview = React.lazy(() => import('./components/AssetPreview'));

export default function MainContainer() {
  const state = useAppStoreSelector((s) => s.state);
  const logout = useAppStoreSelector((s) => s.logout);
  const {isLoading, user, err, integrationFetchError} = state;
  const navigate = useNavigate();
  const isPublicPage = publicRoutes.find((r) =>
    window.location.pathname.startsWith(r.route)
  );

  const ldClient = useLDClient();

  useEffect(() => {
    if (user) {
      ldClient.identify({
        key: user?.email,
        uuid: user?.uuid,
        name: user?.name,
        email: user?.email,
        custom: {
          organization: user?.organization?.name,
          hasSemanticLayer: user?.organization?.has_dbt_semantic_layer,
        },
      });
      Analytics.page(appEventsPA.LOGIN_SUCCESS);
    }
  }, [user, ldClient]);

  useEffect(() => {
    const routerFunc = (newUrl) => navigate(newUrl);
    window.CommandBar.addRouter(routerFunc);
  }, [navigate]);

  if (isLoading) {
    return <PageLoader style={{width: '100vw', height: '100vh'}} />;
  }

  if (err || integrationFetchError) {
    const errStr = err || integrationFetchError;
    return <Error500 errorText={errStr} retryText="Reload" />;
  }

  if (!user) {
    if (
      isPublicPage &&
      !config.localhost.some((url) => window.location.origin.includes(url))
    ) {
      const baseURL = new URL(
        config.environment === 'production'
          ? `https://app.houseware.io`
          : `https://dev.houseware.io`
      );
      const currentURL = new URL(window.location.href);
      if (baseURL.origin !== window.location.origin) {
        const newURL = `${baseURL.toString()}domain_redirect?url=${currentURL.href.replace(
          currentURL.origin,
          ''
        )}`;
        window.location.replace(newURL);
        return <PageLoader style={{width: '100vw', height: '100vh'}} />;
      }
    }

    return <NonAuthenticatedRoutes />;
  }

  if (
    user?.organization?.name &&
    !config.localhost.some((url) => window.location.origin.includes(url))
  ) {
    const orgURL = new URL(
      config.environment === 'production'
        ? `https://${user?.organization?.name}.houseware.io`
        : `https://${user?.organization?.name}.dev.houseware.io`
    );
    const currentURL = new URL(window.location.href);

    if (currentURL.origin !== orgURL.origin) {
      const token = user?.jwt_token;
      const newURL = `${orgURL.toString()}domain_redirect?url=${currentURL.href.replace(
        currentURL.origin,
        ''
      )}&hswrToken=${token}`;
      window.location.replace(newURL);
      logout({redirectURL: '/wait-redirecting'});
      return <PageLoader style={{width: '100vw', height: '100vh'}} />;
    }
  }

  if (!user?.integrations?.is_warehouse_connected) {
    return <WarehouseNotConnectedRoutes />;
  }

  return (
    <KnockFeedProvider
      userId={user?.uuid.toString()}
      apiKey={config.knockPublicKey || ''}
      feedId={config.knockFeedChannelId || ''}
    >
      <TourComponent />
      <AllRoutes />
      <AssetPreview />
      <NotificationToast />
    </KnockFeedProvider>
  );
}
