import {setUser as SentrySetUser, setTag as sentrySetTag} from '@sentry/browser';
import {useQueryClient} from '@tanstack/react-query';
import {message} from 'antd';
import LogRocket from 'logrocket';
import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../config';
import $fetch from '../helpers/$fetch';
import {
  getAuthHeaders,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from '../helpers/auth';
import clearReactQueryCache from '../helpers/localStorage';
import {useStableRef} from '../hooks/useStableRef';
import Analytics from '../services/analytics/Analytics';
import {identifyKnockUserAPI} from '../services/apis';
import {getIntegrations, getOrgUsers} from '../services/apis/user';
import {AppState} from './types';
import {AppDispatch} from './useAppReducer';
import {updatePreviewAsset} from './useAppServices';

async function searchBlockAPI(query: string, type: string) {
  const url = new URL(`${config.apiHostURLV2}/organization/blocks`);
  if (query) {
    url.searchParams.set('query', query);
  }
  if (type) {
    url.searchParams.set('type', type);
  }
  url.searchParams.set('load_content', String(false));
  return $fetch(url.toString());
}

async function searchBlocks(query: string, type: string) {
  const res = await searchBlockAPI(query, type);
  if (res.ok) {
    const results = await res.json();
    return results.blocks.map((data: any) => ({
      name: data.properties?.title,
      uuid: data.uuid,
      parent_uuid: data.parent_block_uuid,
      icon: data.properties?.emoji,
    }));
  } else {
    const errorResponse = await res.json();
    throw new Error(errorResponse.detail);
  }
}

const LOGOUT_EVENT = '__houseware_user_logout__';

export function forceLogoutUser() {
  const logoutEvent = new Event(LOGOUT_EVENT);
  window.dispatchEvent(logoutEvent);
}

function initCommandBar(userData: AppState['user'], dispatch: AppDispatch) {
  window.CommandBar.boot(String(userData.id));
  window.CommandBar.addRecords('apps', [], {
    onInputChange: (query: string) => searchBlocks(query, 'app'),
    labelKey: 'name',
  });
  window.CommandBar.addRecordAction('apps', {
    text: 'Open Workspace',
    name: 'open_app',
    template: {
      type: 'link',
      value: '/workspaces/{{record.parent_uuid}}/{{record.uuid}}',
      operation: 'self', // how should the page open
    },
  });
  window.CommandBar.addRecords('workspaces', [], {
    onInputChange: (query: string) => searchBlocks(query, 'workspace'),
    labelKey: 'name',
  });
  window.CommandBar.addRecordAction('workspaces', {
    text: 'Open Workspace',
    name: 'open_workspace',
    template: {
      type: 'link',
      value: '/workspaces/{{record.uuid}}',
      operation: 'self', // how should the page open
    },
  });

  window.CommandBar.addCallback('preview', function preview() {
    updatePreviewAsset(
      {
        type: 'event',
        view: 'Show all Events',
      },
      dispatch
    );
  });
}

function initAuthenticatedServices(userData: AppState['user'], dispatch: AppDispatch) {
  try {
    // identify user on user analytics tools
    Analytics.identify(userData);
    // initialise commandbar (cmd + k)
    initCommandBar(userData, dispatch);
    if (config.logRocketKey) {
      LogRocket.identify(userData.id, {
        name: userData.name,
        email: userData.email,
        userId: userData.id,
      });
    }
    sentrySetTag('user_email', userData.email);
    sentrySetTag('user_org', userData?.organization?.name);
    SentrySetUser({
      id: userData.id,
      email: userData.email,
      // key/value pairs beyond the reserved names
      // NOTE: We're duplicationg user_id, username, name here.
      //       Do we really need all of them?
      // NOTE: Is there any case where we could have an user without an organization?
      user_id: userData.id,
      username: userData.name,
      name: userData.name,
      organization_id: userData?.organization?.id,
      organization_name: userData?.organization?.name,
    });
    identifyKnockUserAPI();
  } catch {}
}

export default function useUserServices(dispatch: AppDispatch, _state: AppState) {
  const navigate = useNavigate();
  const stateRef = useStableRef(_state);
  const queryClient = useQueryClient();

  const fetchOrgIntegrations = useCallback(
    async (prevUser: AppState['user'], callback: (prop: unknown) => void) => {
      try {
        const user = prevUser || stateRef?.current?.user || {};
        const integrationRes = await getIntegrations();
        if (integrationRes.ok) {
          const integrationData = await integrationRes.json();
          user.integrations = integrationData;
        }
        const newUser = {...user};
        if (callback) callback(newUser);
      } catch {
        dispatch({
          type: 'err-login-integration',
          payload:
            "Couldn't fetch organization data sources, Something went wrong! Please try again",
        });
      }
    },
    [stateRef, dispatch]
  );

  const updateOrgUsers = useCallback(async () => {
    dispatch({type: 'update-org-users', payload: {isLoading: true}});
    try {
      const users = await getOrgUsers();
      dispatch({type: 'update-org-users', payload: {users, isLoading: false}});
    } catch (error) {
      message.error(
        'Something went wrong, If this error persists, please contact Houseware support'
      );
      dispatch({type: 'update-org-users', payload: {users: [], isLoading: false}});
    }
  }, [dispatch]);

  const resetApp = useCallback(
    (isSwitchOrg?: boolean) => {
      clearReactQueryCache(config.reactQueryOfflineCacheKey);
      queryClient.clear();
      // if switch org, no need to shutdown comandbar
      if (!isSwitchOrg && window.CommandBar) {
        window.CommandBar.shutdown();
      }
    },
    [queryClient]
  );

  const swtichActiveOrganization = useCallback(
    async (newOrgId: number, callback: () => void) => {
      dispatch({type: 'loading-login'});
      try {
        const authURL = `${config.apiHostURL}/auth/switch/organization/${newOrgId}`;
        const res = await $fetch(authURL, {
          method: 'POST',
        });
        if (res.status === 200) {
          const user = await res?.json();
          // remove react query offline cache stored in localstorage
          resetApp(true);
          setAuthToken(user?.jwt_token);
          await fetchOrgIntegrations(user, (updatedUser) => {
            dispatch({type: 'login', payload: {user: updatedUser}});
          });
          await updateOrgUsers();
          if (typeof callback === 'function') callback();
        } else {
          throw Error('Switching organization failed');
        }
      } catch (_e) {
        dispatch({
          type: 'err-login',
          payload:
            'Switching organization failed. If this keeps happening, Reach us at support@houseware.io',
        });
      }
    },
    [dispatch, fetchOrgIntegrations, resetApp, updateOrgUsers]
  );

  const login = useCallback(
    async (authToken: string) => {
      dispatch({type: 'loading-login'});
      try {
        const authURL = new URL(`${config.apiHostURL}/auth/signup`);
        authURL.searchParams.append('auth_token', authToken);
        const res = await fetch(authURL.toString(), {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        if (res.status === 200) {
          const user = await res.json();
          setAuthToken(user?.jwt_token);
          await fetchOrgIntegrations(user, (updatedUser) => {
            dispatch({type: 'login', payload: {user: updatedUser}});
            initAuthenticatedServices(user, dispatch);
          });
          await updateOrgUsers();
        } else {
          throw Error('Login failed');
        }
      } catch (_e) {
        dispatch({
          type: 'err-login',
          payload:
            'Looks like your user account is not whitelisted. Reach us at support@houseware.io',
        });
      }
    },
    [dispatch, fetchOrgIntegrations, updateOrgUsers]
  );

  const logout = useCallback(
    async ({redirectURL}: {redirectURL?: string} = {}) => {
      try {
        const authHeaders = getAuthHeaders();
        if (Object.keys(authHeaders).length > 0) {
          await fetch(`${config.apiHostURL}/auth/logout`, {
            method: 'POST',
            headers: authHeaders,
          });
        }
      } catch {}
      dispatch({type: 'logout'});
      // remove react query offline cache stored in localstorage
      resetApp();
      removeAuthToken();
      // redirect to redirect url or home
      navigate(redirectURL || '/');
    },
    [dispatch, navigate, resetApp]
  );

  const updateUser = useCallback(
    (user: AppState['user']) => {
      dispatch({type: 'update-user', payload: user});
    },
    [dispatch]
  );

  const refetchUserData = useCallback(async () => {
    try {
      const headers = getAuthHeaders();
      const res = await fetch(`${config.apiHostURL}/auth/login/jwt`, {
        method: 'POST',
        headers,
      });
      if (res.status === 200) {
        const user = await res.json();
        await fetchOrgIntegrations(user, (updatedUser) => {
          dispatch({type: 'login', payload: {user: updatedUser}});
        });
        await updateOrgUsers();
      }
    } catch {}
  }, [dispatch, fetchOrgIntegrations, updateOrgUsers]);

  // initialise the app with previously stored token if any
  useEffect(() => {
    const tokenInStorage = getAuthToken();
    const params = new URLSearchParams(window.location.search);
    const hswrToken = params.get('hswrToken');
    // hswrToken is supplied in url param if user is being redirected to another domain after login
    const token = hswrToken || tokenInStorage;

    if (token) {
      setAuthToken(token);
      (async () => {
        dispatch({type: 'loading-login'});
        try {
          const headers = getAuthHeaders();
          const res = await fetch(`${config.apiHostURL}/auth/login/jwt`, {
            method: 'POST',
            headers,
          });
          if (res.status === 200) {
            const user = await res?.json();
            await fetchOrgIntegrations(user, (updatedUser) => {
              dispatch({type: 'login', payload: {user: updatedUser}});
              initAuthenticatedServices(user, dispatch);
            });
            await updateOrgUsers();
          } else {
            throw Error('Login failed');
          }
        } catch (_e) {
          removeAuthToken();
          message.error(
            'Looks like your token has expired, please refresh the page and login again'
          );
          dispatch({type: 'logout'});
        }
      })();
    } else {
      dispatch({type: 'logout'});
      resetApp();
    }
  }, [dispatch, fetchOrgIntegrations, resetApp, updateOrgUsers]);

  // handle force logut
  useEffect(() => {
    function logoutEventHandler() {
      const locationURL = window.location.pathname;
      message.destroy();
      message.info('Session expired, Please login again!');
      logout({redirectURL: locationURL});
    }
    window.addEventListener(LOGOUT_EVENT, logoutEventHandler);
    return () => window.removeEventListener(LOGOUT_EVENT, logoutEventHandler);
  }, [logout]);

  const refetchOrgIntegrationAndUpdateStore = useCallback(() => {
    fetchOrgIntegrations(undefined, updateUser);
  }, [fetchOrgIntegrations, updateUser]);

  return {
    login,
    logout,
    updateUser,
    swtichActiveOrganization,
    fetchOrgIntegrations,
    refetchOrgIntegrationAndUpdateStore,
    refetchUserData,
  };
}
