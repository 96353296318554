import {useContextSelector} from 'use-context-selector';
import {AppStore, StoreContext} from './StoreProvider';

type ContextSelector = (state: AppStore) => any;

type ContextReturnType<T extends (...args: any) => any> = ReturnType<T>;

const useAppStoreSelector = <T extends ContextSelector>(
  selector: T
): ContextReturnType<T> => useContextSelector(StoreContext, selector);

export default useAppStoreSelector;
