import config from '../../config';
import $fetch from '../../helpers/$fetch';

// User & org apis

export async function getIntegrations() {
  return $fetch(`${config.apiHostURL}/organization/integration`);
}

export async function getOrgUsers() {
  const res = await $fetch(`${config.apiHostURL}/organization/users`);
  if (res.ok) {
    return res.json();
  }
  return [];
}

export async function fetchUserAccessAPI(payload, action) {
  const url = `${config.apiHostURLV2}/organization/access-control/user`;
  return $fetch(url, {
    method: action === 'invite' ? 'POST' : 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}
export async function fetchOrgAccessAPI(payload) {
  const url = `${config.apiHostURLV2}/organization/access-control/organization`;
  return $fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export async function inviteUserAPI({name, email, isUserRole}) {
  const url = new URL(`${config.apiHostURL}/organization/users/invite`);
  url.searchParams.append('name', name);
  url.searchParams.append('email_id', email);
  url.searchParams.append('user_role', isUserRole);

  const res = await $fetch(url.toString(), {
    method: 'POST',
  });

  return res;
}

export async function updateUserAdminAPI({userId, isUserRole}) {
  const url = new URL(`${config.apiHostURL}/organization/users/admin-access`);
  const payload = {
    user_id: userId,
    user_role: isUserRole,
  };
  return $fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}
