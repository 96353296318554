import {useReducer} from 'react';
import {AppAction, AppState} from './types';

const initialState: AppState = {
  isLoading: true,
  user: null,
  err: null,
  config: {
    isSiderCollapsed: null,
  },
  orgUsers: null,
  integrationFetchError: null,
  metaData: {},
  blockConfigureState: null,
  showShareModal: false,
  fullScreenConfigView: false,
};

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'update-user':
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          ...action?.payload,
        },
      };
    case 'update-org-users':
      return {
        ...state,
        isLoading: action.payload?.isLoading || false,
        orgUsers: action.payload?.users,
      };
    case 'loading-login':
      return {
        ...state,
        isLoading: true,
        user: null,
      };
    case 'err-login':
      return {
        ...state,
        isLoading: false,
        user: null,
        err: action.payload,
      };
    case 'err-login-integration':
      return {
        ...state,
        isLoading: false,
        user: null,
        integrationFetchError: action.payload,
      };
    case 'login':
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          ...action.payload?.user,
        },
      };
    case 'logout':
      return {
        ...state,
        isLoading: false,
        user: null,
        orgUsers: [],
      };
    case 'update_app_config':
      return {
        ...state,
        config: {
          ...state.config,
          ...action?.payload,
        },
      };
    case 'update_block_configure_ui':
      return {
        ...state,
        blockConfigureState: action.payload,
      };

    case 'update_asset_preview_state':
      return {
        ...state,
        assetPreviewState: action.payload,
      };
    case 'toggle-share-modal':
      return {
        ...state,
        showShareModal: action.payload,
      };

    case 'toggle-full-screen-config-view':
      return {
        ...state,
        fullScreenConfigView: action.payload,
      };

    default:
      return state;
  }
}

export default function useAppReducer() {
  return useReducer(reducer, initialState);
}

export type AppDispatch = ReturnType<typeof useAppReducer>[1];
