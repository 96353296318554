import cloneDeep from 'lodash/cloneDeep';
import {BlockData, FunnelsConfiguration} from './types';

export const metricsQueryKeys = {
  all: ['metrics'] as const,
  public: () => [...metricsQueryKeys.all, 'public'] as const,
  org: () => [...metricsQueryKeys.all, 'org'] as const,
  publicOrOrg: ({isTemplateScreen}: {isTemplateScreen: boolean}) =>
    isTemplateScreen ? metricsQueryKeys.public() : metricsQueryKeys.org(),
};

export const metricValueKeys = {
  all: ['metric-value'] as const,
  byId: ({id}: {id: string}) => [...metricValueKeys.all, id] as const,
};

export const goalsQueryKeys = {
  all: ['goals'],
};

export const connectionsQueryKeys = {
  all: ['connectors'],
};

export const blocksQueryKeys = {
  all: ['blocks'],
  recents: (path: string) => ['recent-blocks', path],
  byId: ({id}: {id: string}) => [...blocksQueryKeys.all, id] as const,
};
export const commentsQueryKeys = {
  all: ['comments'],
  byId: ({id}: {id: string}) => [...commentsQueryKeys.all, id] as const,
};

export const metricsDataQueryKeys = {
  all: ['metricsData'],
  byId: ({id, payload}: {id: string; payload?: any}) =>
    [...metricsDataQueryKeys.all, id, payload ? JSON.stringify(payload) : 'all'] as const,
  byBlockConfig: ({block}: {block: any}) =>
    [
      ...metricsDataQueryKeys.all,
      {
        representation: block.properties.representation,
        legends: block.properties.legends,
        sankey_nodes: block.properties.sankey_nodes,
        show_multiple_y_axis: block.properties.show_multiple_y_axis,
      },
      JSON.stringify(block.configuration),
    ] as const,
};

export const modelPreviewQueryKeys = {
  all: ['modelPreview'],
  byCustomConfig: ({payload}: {payload: any}) => [
    ...modelPreviewQueryKeys.all,
    JSON.stringify(payload),
  ],
};

export const modelPreviewColumnQueryKeys = {
  all: ['modelPreviewColumn'],
  byCustomConfig: ({payload}: {payload: any}) => [
    ...modelPreviewColumnQueryKeys.all,
    JSON.stringify(payload),
  ],
};

export const modelDataQueryKeys = {
  all: ['modelData'],
  byCustomConfig: ({payload}: {payload: any}) =>
    [...modelDataQueryKeys.all, JSON.stringify(payload)] as const,
};

export const modelsQueryKeys = {
  all: ['models'],
};

export const exposedModelQueryKeys = {
  all: ['exposedModels'],
};

function getCommonConfigKeyFromBlockForPAVizs(block: BlockData<'visualization'>) {
  return [
    JSON.stringify(block?.configuration?.dateFilter ?? {}),
    JSON.stringify(block?.configuration?.compare ?? {}),
    JSON.stringify(block?.configuration?.meta ?? {}),
  ];
}

function getCommonConfigKeyFromAppForPAVizs(
  appConfig: BlockData<'app'>['configuration']
) {
  return [
    JSON.stringify(appConfig?.cohort_filters ?? {}),
    JSON.stringify(appConfig?.dateFilter ?? {}),
    JSON.stringify(appConfig?.date_filters ?? {}),
    JSON.stringify(appConfig?.dynamic_date_filter ?? {}),
  ];
}

export const visualisationRootQueryKey = ['visualisation'];

export const flowsQueryKeys = {
  all: [...visualisationRootQueryKey, 'flows'],
  byBlockDataV1: ({
    blockData,
  }: {
    blockData: BlockData<'visualization'>;
    appData: BlockData<'app'>;
  }) => [
    ...flowsQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    JSON.stringify({
      representation: blockData?.properties?.representation,
      configuration: Object.keys(blockData?.configuration || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration || {})[key];
          return obj;
        }, {}),
    }),
  ],
  byBlockData: ({
    blockData,
    appData,
  }: {
    blockData: BlockData<'visualization'>;
    appData: BlockData<'app'>;
  }) => [
    ...flowsQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    JSON.stringify(appData?.configuration?.dateFilter ?? {}),
    JSON.stringify({
      representation: blockData?.properties?.representation,
      configuration: Object.keys(blockData?.configuration.user_flow || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.user_flow || {})[key];
          return obj;
        }, {}),
    }),
  ],
};

export const insightsQueryKeys = {
  all: [...visualisationRootQueryKey, 'insights'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...insightsQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.insights || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.insights || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const funnelsQueryKeys = {
  all: [...visualisationRootQueryKey, 'funnels'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => {
    const funnelConfig = cloneDeep(
      blockData.configuration.funnels || ({} as FunnelsConfiguration)
    );
    if (funnelConfig?.view_config) {
      if (funnelConfig?.view_config?.from_event) {
        if (funnelConfig.view_config.from_event_index === 0) {
          // delete properties stored for backward compatibility
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.view_config.from_event_index;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.view_config.target_event_index;
        }
      } else if (funnelConfig?.view_config !== undefined) {
        delete funnelConfig.view_config.from_event;
        delete funnelConfig.view_config.target_event;
      }
    }

    if (funnelConfig?.time_buckets) {
      if (funnelConfig?.time_buckets?.from_event) {
        if (funnelConfig.time_buckets.from_event_index === 0) {
          // delete properties stored for backward compatibility
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.time_buckets.from_event_index;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.time_buckets.target_event_index;
        }
      } else if (funnelConfig?.time_buckets !== undefined) {
        delete funnelConfig.time_buckets.from_event;
        delete funnelConfig.time_buckets.target_event;
      }
    }

    return [
      ...flowsQueryKeys.all,
      ...getCommonConfigKeyFromBlockForPAVizs(blockData),
      ...getCommonConfigKeyFromAppForPAVizs(appConfig),

      JSON.stringify({
        config: Object.keys(funnelConfig)
          .sort()
          .reduce((obj: any, key: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj[key] = funnelConfig[key];
            return obj;
          }, {}),
      }),
      blockData.properties.representation,
    ];
  },
};

export const retentionQueryKeys = {
  all: [...visualisationRootQueryKey, 'retention'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...retentionQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.retention_viz || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.retention_viz || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const stickinessQueryKeys = {
  all: [...visualisationRootQueryKey, 'stickiness'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...stickinessQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.stickiness_viz || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.stickiness_viz || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const eventsQueryKeys = {
  all: ['events'],
  customEvents: () => [eventsQueryKeys.all, 'custom_events'],
  hiddenEvents: () => [eventsQueryKeys.all, 'hidden_events'],
};

export const eventMetadataQueryKeys = {
  all: ['event-metadata'],
  byEventName: ({eventName}: {eventName: string}) =>
    [...eventMetadataQueryKeys.all, eventName] as const,
};

export const segmentsQueryKeys = {
  all: ['segments'],
  byModelId: ({modelId}: {modelId: string}) =>
    [...segmentsQueryKeys.all, modelId] as const,
};

export const organizationUsersQueryKeys = {
  byId: ({id}: {id: string}) => ['organization', id, 'users'] as const,
};

export const favouriteAppQueryKey = {
  all: () => ['favouriteApp'],
};

export const knockQueryKeys = {
  all: ['knock'],
};

export const cohortsQueryKey = {
  all: () => ['cohorts'],
  byId: (id: string) => [cohortsQueryKey.all(), id],
};

export const userLookupQueryKeys = {
  all: () => ['user-lookup'],
  byPayload: (payload: unknown) => [userLookupQueryKeys.all(), JSON.stringify(payload)],
};

export const eventTagsKeys = {
  all: () => ['event-tags'],
};
