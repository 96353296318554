import {AppState, OrgUsers} from '../../../../store/types';

// Matches @[name](id) format
export const mentionPatternWithVariableID = /@\[([^)]+)\]\(\d+\)/g;
// Matches @[name](123) format to get arr = [name, 123]
export const mentionPatternWithNumericID = /@\[([^)]+)\]\(([^)]+)\)/g;

// this function formats the raw comment
// a comment is stored as "<user_id> Hi @[__display__](__id__)!"
// we want to show this comment on screen as "Hi <__display__>!"
export const getFormattedCommentText = (comment: string | null, isPrefixId: boolean) => {
  if (typeof comment !== 'string') return '';
  if (isPrefixId) {
    return comment
      ?.slice(comment.indexOf(' ') + 1)
      .replace(mentionPatternWithVariableID, '$1');
  }
  return comment?.replace(mentionPatternWithVariableID, '$1');
};

const orgUserEmailMap: Record<string, OrgUsers[number]> = {};

export function getAuthorName(orgUsers: AppState['orgUsers'], authorEmail = '') {
  if (orgUserEmailMap[authorEmail!]) return orgUserEmailMap[authorEmail].name || '';
  orgUsers?.forEach((user) => {
    orgUserEmailMap[user.email] = user;
  });
  return orgUserEmailMap[authorEmail].name || '';
}
