import {useCallback} from 'react';
import {BlockDispatch} from './useBlockReducer';

export default function useBlockServices(dispatch: BlockDispatch) {
  const toggleBlockComments = useCallback(
    (openComments: boolean) => {
      dispatch({type: 'update-comments-pane', payload: openComments});
    },
    [dispatch]
  );

  return {
    toggleBlockComments,
  };
}
