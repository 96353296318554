import {Comment} from '@ant-design/compatible';
import {NotificationOutlined} from '@ant-design/icons';
import {useKnockFeed} from '@knocklabs/react-notification-feed';
import {useQuery} from '@tanstack/react-query';
import {Empty, Flex, List, Modal, Result, Skeleton, Tag, notification} from 'antd';
import dayjs from 'dayjs';
import {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {knockQueryKeys} from '../../../../queryKeyFactory';
import {identifyKnockUserAPI} from '../../../../services/apis';
import useAppStoreSelector from '../../../../store/useAppStoreSelector';
import HousewareUserAvatar from '../../../common/HousewareUserAvatar';
import './Notification.css';
import {getFormattedCommentText} from './NotificationHelpers';

type NotificationListProps = {
  data: any;
  loadingStatus: boolean;
  style?: CSSProperties;
};

const NotificationList = ({data, loadingStatus, style = {}}: NotificationListProps) => {
  const {feedClient} = useKnockFeed();
  const navigate = useNavigate();

  if (!data.length && loadingStatus) {
    return <Skeleton active />;
  }

  if (!data.length) {
    return (
      <Flex align="center" justify="center">
        <Empty
          image="/empty_notif.svg"
          imageStyle={{height: 60}}
          description={
            <span>
              Psst! It's quiet in here. Collaborate with your teammates by adding comments
              and mentioning them on the visualizations!
            </span>
          }
        />
      </Flex>
    );
  }

  return (
    <List
      itemLayout="horizontal"
      style={style}
      dataSource={data}
      renderItem={(item: any) => (
        <List.Item
          style={{width: '100%'}}
          className="notif-list-item"
          onClick={() => {
            const commentOriginURL = item.data.link;
            feedClient.markAsRead(item);
            navigate(commentOriginURL);
          }}
        >
          <Skeleton avatar title={false} loading={loadingStatus} active>
            <Comment
              style={{width: '100%', borderRadius: 8, padding: 8}}
              key={item.id}
              author={item?.actors[0]?.name}
              datetime={dayjs(item?.inserted_at).format('DD MMM YYYY')}
              avatar={<HousewareUserAvatar email={item?.actors[0]?.email} />}
              content={<p>{getFormattedCommentText(item?.data?.message, false)}</p>}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

export function ShowAllNotificationModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {feedClient, useFeedStore} = useKnockFeed();
  const {items, networkStatus} = useFeedStore();

  const {user: currentUser} = useAppStoreSelector((s) => s.state);
  const {isError, isLoading: isKnockLoading} = useQuery({
    queryKey: knockQueryKeys.all,
    queryFn: async () => {
      return identifyKnockUserAPI();
    },
  });

  const {commentNotifications} = useMemo(() => {
    const allCommentNotifications =
      items?.filter((message) => {
        if (message?.data?.conversation_type !== 'comments') return false;
        if (!!message?.data.org_id) {
          if (
            message?.data.org_id === currentUser.organization.id &&
            message?.data.org_name === currentUser.organization.name
          ) {
            return true;
          }
          return false;
        }

        return true;
      }) || [];

    return {
      commentNotifications: allCommentNotifications,
    };
  }, [items, currentUser]);

  useEffect(() => {
    if (commentNotifications.length === 0) {
      feedClient.fetch();
    }
  }, [feedClient, commentNotifications.length]);
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Tag
        onClick={() => {
          setIsModalVisible(true);
        }}
        icon={<NotificationOutlined />}
        style={{
          marginRight: '1rem',
          fontSize: '.95rem',
          padding: '2px 4px',
          cursor: 'pointer',
        }}
      >
        Notifications
      </Tag>
      <Modal
        title={'All Notifications'}
        open={isModalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        styles={{
          body: {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: '60vh',
            display: 'flex',
            overflow: 'scroll',
          },
        }}
        footer={null}
      >
        {isError || networkStatus === 'error' ? (
          <Flex align="center" justify="center" style={{flexGrow: 1}}>
            <Result
              status="500"
              subTitle={'Sorry, something went wrong while fetching notifications'}
            />
          </Flex>
        ) : (
          <NotificationList
            data={commentNotifications}
            loadingStatus={
              networkStatus === 'loading' ||
              networkStatus === 'fetchMore' ||
              isKnockLoading
            }
            style={{height: '60vh', overflowY: 'scroll', flexGrow: 1}}
          />
        )}
      </Modal>
    </>
  );
}

export function NotificationToast() {
  const navigate = useNavigate();
  const {feedClient} = useKnockFeed();
  const [antdNotificationAPI, antdNotificationContextHolder] =
    notification.useNotification();

  const onNotificationsReceived = useCallback(
    ({items}: {items: any}) => {
      // Whenever we receive a new notification from our real-time stream, show a toast
      // (note here that we can receive > 1 items in a batch)
      items.forEach((notif: any) => {
        if (!notif.data.show_toast || notif.data.conversation_type === 'annotations') {
          return;
        }

        antdNotificationAPI.info({
          description: getFormattedCommentText(notif.data.message, false),
          message: notif?.actors[0]?.name,
          placement: 'topRight',
          icon: (
            <HousewareUserAvatar
              email={notif?.actors[0]?.email}
              avatarProps={{style: {verticalAlign: 'middle'}}}
            />
          ),
          onClick: () => navigate(notif.data.link),
        });
      });
    },
    [antdNotificationAPI, navigate]
  );

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on('items.received.realtime', onNotificationsReceived);

    // Cleanup
    return () => feedClient.off('items.received.realtime', onNotificationsReceived);
  }, [feedClient, onNotificationsReceived]);
  return antdNotificationContextHolder;
}
