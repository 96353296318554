import {useReducer} from 'react';
import {BlockAction, BlockState} from './types';

const initialState: BlockState = {
  isCommentsSectionOpen: false,
};

function reducer(state: BlockState, action: BlockAction): BlockState {
  switch (action.type) {
    case 'update-comments-pane':
      return {
        ...state,
        isCommentsSectionOpen: action.payload,
      };

    default:
      return state;
  }
}

export default function useBlockReducer() {
  return useReducer(reducer, initialState);
}

export type BlockDispatch = ReturnType<typeof useBlockReducer>[1];
