import {AppThemeModeType, AppThemeType} from './AppThemeProvider';

type ThemeColorTypes = {
  '--primary-110': string;
  '--primary-100': string;
  '--primary-90': string;
  '--primary-60': string;
  '--primary-30': string;
  '--primary-15': string;
  '--primary-10': string;
  '--primary-5': string;
  '--secondary-100': string;
  '--secondary-90': string;
  '--secondary-10': string;
  '--chart-colors': string[];
};

export const themes: Record<AppThemeType, Record<AppThemeModeType, ThemeColorTypes>> = {
  housewarePink: {
    light: {
      '--primary-110': '#6b205a',
      //Base color
      '--primary-100': '#6d1d4f',
      '--primary-90': '#7d365f',
      '--primary-60': '#b17e9a',
      '--primary-30': '#d4b9c6',
      '--primary-15': '#e3d0d9',
      '--primary-10': '#f1e7ec',
      '--primary-5': '#f8f3f5',

      '--secondary-100': '#3f7623',
      '--secondary-90': '#55853b',
      '--secondary-10': '#e9efe5',
      '--chart-colors': [
        '#5a014f',
        '#d682c8',
        '#ffabf3',
        '#fad0f4',
        '#eac288',
        '#da84b0',
        '#cb47d8',
        '#dc5cc6',
        '#ee72b4',
        '#ff87a2',
        '#842d76',
        '#ad599d',
      ],
    },
  },
};
