import {AppThemeModeType, AppThemeType} from './AppThemeProvider';
import {themes} from './themes';

const getNeutralColors = (themeType: AppThemeModeType) => {
  if (themeType === 'light')
    return {
      '--neutral-100': '#070707',
      '--neutral-95': '#151515',
      '--neutral-90': '#1f1f1f',
      '--neutral-85': '#2a2a2a',
      '--neutral-80': '#353535',
      '--neutral-75': '#404040',
      '--neutral-70': '#4b4b4b',
      '--neutral-65': '#575757',
      '--neutral-60': '#636363',
      '--neutral-55': '#707070',
      '--neutral-50': '#7c7c7c',
      '--neutral-45': '#898989',
      '--neutral-40': '#969696',
      '--neutral-35': '#a3a3a3',
      '--neutral-30': '#b1b1b1',
      '--neutral-25': '#bfbfbf',
      '--neutral-20': '#cccccc',
      '--neutral-15': '#dadada',
      '--neutral-10': '#e9e9e9',
      '--neutral-5': '#f4f4f4',
      '--neutral-0': '#fbfbfb',
    };
  else
    return {
      '--neutral-0': '#1c1e21',
      '--neutral-5': '#25272a',
      '--neutral-10': '#2f3033',
      '--neutral-15': '#38393c',
      '--neutral-20': '#424245',
      '--neutral-25': '#4c4c4f',
      '--neutral-30': '#575659',
      '--neutral-35': '#616063',
      '--neutral-40': '#6c6b6d',
      '--neutral-45': '#777578',
      '--neutral-50': '#828082',
      '--neutral-55': '#8d8b8d',
      '--neutral-60': '#989698',
      '--neutral-65': '#a3a2a3',
      '--neutral-70': '#aeadae',
      '--neutral-75': '#bab9ba',
      '--neutral-80': '#c6c5c5',
      '--neutral-85': '#d1d0d1',
      '--neutral-90': '#dddddd',
      '--neutral-95': '#e9e9e9',
      '--neutral-100': '#f5f5f5',
    };
};

const housewareColors = {
  '--border': 'var(--neutral-20)',

  '--background': 'var(--neutral-5)',
  '--dark-foreground': 'var(--neutral-10)',
  '--light-foreground': 'var(--neutral-0)',
  '--disabled-bg': 'var(--neutral-10)',

  '--danger': '#cf1322',
  '--success': '#3f8600',

  '--heading-text': 'var(--neutral-100)',
  '--primary-text': 'var(--neutral-80)',
  '--secondary-text': 'var(--neutral-50)',
  '--tertiary-text': 'var(--neutral-30)',
  '--link-text': 'var(--primary-90)',
  '--disabled-text': 'var(--neutral-30)',
};

export const getThemeColors = ({
  mode,
  theme,
}: {
  mode: AppThemeModeType;
  theme: AppThemeType;
}) => {
  const neutralColors = getNeutralColors(mode);
  return {
    ...neutralColors,
    ...housewareColors,
    ...themes.housewarePink[mode],
  };
};
