import {AnalyticsEvent, AnalyticsEventPA} from '../../types';

const appEvents = {
  LOGIN_SUCCESS: {
    category: {
      category: 'Signup',
    },
    name: 'Login Success',
  },
  ADMIN_PAGE_VIEWED: {
    name: 'Admin Page Viewed',
    category: 'Engagement - Admin',
  },
  CONNECTIONS_PAGE_VIEWED: {
    name: 'Connections Page Viewed',
    category: 'Engagement - Connections',
  },
  DBT_DETAILS_PAGE_VIEWED: {
    name: 'Dbt_Details Page Viewed',
    category: 'Engagement - Connections',
  },
  DBT_REPO_DETAILS_PAGE_VIEWED: {
    name: 'Dbt Repo Details_Page Viewed',
    category: 'Engagement - Connections',
  },
  WAREHOUSE_DETAILS_PAGE_VIEWED: {
    name: 'Warehouse Details Page Viewed',
    category: 'Engagement - Connections',
  },
  CONNECTED_SOURCES_TAB_CLICKED: {
    name: 'Connected Sources Tab Clicked',
    category: 'Engagement - Connections',
  },
  DATA_SOURCES_TAB_CLICKED: {
    name: 'Data Sources Tab Clicked',
    category: 'Engagement - Connections',
  },
  ORGANIZATION_CONNECTIONS_TAB_CLICKED: {
    name: 'Organization Connections Tab Clicked',
    category: 'Engagement - Connections',
  },
  SEE_DBT_DETAILS_CLICKED: {
    name: 'See DBT Details Clicked',
    category: 'Engagement - Connections',
  },
  SEE_GITHUB_DETAILS_CLICKED: {
    name: 'See Github Details Clicked',
    category: 'Engagement - Connections',
  },
  SEE_WAREHOUSE_DETAILS_CLICKED: {
    name: 'See Warehouse Details Clicked',
    category: 'Engagement - Connections',
  },
  ERROR_PAGE_VIEWED: {
    name: 'Error Page Viewed',
    category: 'Engagement - Error',
  },
  GOALS_PAGE_VIEWED: {
    name: 'Goals Page Viewed',
    category: 'Engagement - Goals',
  },
  SET_NEW_GOAL_PAGE_VIEWED: {
    name: 'Set New Goal Page Viewed',
    category: 'Engagement - Goals',
  },
  METRICS_DETAILS_PAGE_VIEWED: {
    name: 'Metric Details Page Viewed',
    category: 'Engagement - Metrics',
  },
  METRICS_PAGE_VIEWED: {
    name: 'Metrics Page Viewed',
    category: 'Engagement - Sidebar',
  },
  REQUEST_NEW_METRIC_PAGE_VIEWED: {
    name: 'Request New Metric Page Viewed',
    category: 'Engagement - Metrics',
  },
  EXPLORE_METRIC_CLICKED: {
    name: 'Explore Metric Clicked',
    category: 'Engagement - Metrics',
  },
  EXPAND_METRIC_CLICKED: {
    name: 'Expand Metric Clicked',
    category: 'Engagement - Metrics',
  },
  NEW_ALERT_CLICKED: {
    name: 'New Alert Clicked',
    category: 'Engagement - Metrics',
  },
  PREVIEW_METRIC_CLICKED: {
    name: 'Preview Metric Clicked',
    category: 'Engagement - Metrics',
  },
  ADD_TO_CHROME_CLICKED: {
    name: 'Add To Chrome Clicked',
    category: 'Engagement - Sidebar',
  },
  ADD_TO_SLACK_CLICKED: {
    name: 'Add To Slack Clicked',
    category: 'Engagement - Sidebar',
  },
  HOUSEWARE_EVERYWHERE_CLICKED: {
    name: 'Houseware Everywhere Clicked',
    category: 'Engagement - Sidebar',
  },
  LOGOUT_CLICKED: {
    name: 'Logout Clicked',
    category: 'Engagement - Sidebar',
  },
  ADMIN_CLICKED: {
    name: 'Admin Clicked',
    category: 'Engagement - Sidebar',
  },
  CONNECTIONS_CLICKED: {
    name: 'Connections Clicked',
    category: 'Engagement - Sidebar',
  },
  DOCUMENTATION_CLICKED: {
    name: 'Documentation Clicked',
    category: 'Engagement - Sidebar',
  },
  ENTITY_HUB_CLICKED: {
    name: 'Entity Hub Clicked',
    category: 'Engagement - Sidebar',
  },
  TEMPLATES_CLICKED: {
    name: 'Templates Clicked',
    category: 'Engagement - Sidebar',
  },
  GOALS_CLICKED: {
    name: 'Goals Clicked',
    category: 'Engagement - Sidebar',
  },
  MY_METRICS_CLICKED: {
    name: 'My Metrics Clicked',
    category: 'Engagement - Metrics',
  },
  ORG_IMAGE_CLICKED: {
    name: 'Org Image Clicked',
    category: 'Engagement - Sidebar',
  },
  SIDEBAR_WORKSPACES_CLICKED: {
    name: 'Sidebar Workspaces Clicked',
    category: 'Engagement - Sidebar',
  },
  CREATE_CLICKED: {
    name: 'Create Visualizations Clicked',
    category: 'Engagement - Sidebar',
  },
  SIDEBAR_DRAFTS_CLICKED: {
    name: 'Sidebar Drafts Clicked',
    category: 'Engagement - Sidebar',
  },
  TEMPLATE_LIVE_DEMO_PAGE_VIEWED: {
    name: 'Template Live Demo Page Viewed',
    category: 'Engagement - Templates',
  },
  TEMPLATES_PAGE_VIEWED: {
    name: 'Templates Page Viewed',
    category: 'Engagement - Templates',
  },

  APPLY_FILTERS_AND_RELOAD_CLICKED: {
    name: 'Apply Filters And Reload Clicked',
    category: 'Engagement - Workspace',
  },
  CHOOSE_A_TEMPLATE_CLICKED: {
    name: 'Choose A Template Clicked',
    category: 'Engagement - Workspace',
  },
  PARAMTERIZE_APP_CLICKED: {
    name: 'Paramterize App Clicked',
    category: 'Engagement - Workspace',
  },
  GETTING_STARTED_PAGE_VIEWED: {
    name: 'Getting Started Page Viewed',
    category: 'Engagement - Workspaces',
  },
  WORKSPACE_PAGE_VIEWED: {
    name: 'Workspace Page Viewed',
    category: 'Engagement - Workspaces',
  },
  WORKSPACES_PAGE_VIEWED: {
    name: 'Workspaces Page Viewed',
    category: 'Engagement - Workspaces',
  },
  BREADCRUMB_CLICKED: {
    name: 'Breadcrumb Clicked',
    category: 'Engagement - Workspaces',
  },
  ADD_NEW_BLOCK_CLICKED: {
    name: 'Add New Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  BLOCK_COMMENT_ADD_CLICKED: {
    name: 'Block Comment Added',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  BLOCK_COMMENT_CLICKED: {
    name: 'Block Comment Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  BLOCK_INFO_CLICKED: {
    name: 'Block Info Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  BLOCK_MENU_CLICKED: {
    name: 'Block Menu Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  BLOCK_TYPE_SELECTED_CLICKED: {
    name: 'Block Type Selected Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  CANCEL_BLOCK_CONFIG_CLICKED: {
    name: 'Cancel Block Configuration Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  CONFIGURE_BLOCK_CLICKED: {
    name: 'Configure Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  SAVE_BLOCK_CONFIG_CLICKED: {
    name: 'Save Block Configuration Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  COPY_BLOCK_CLICKED: {
    name: 'Copy Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  DELETE_BLOCK_CLICKED: {
    name: 'Delete Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  DUPLICATE_BLOCK_CLICKED: {
    name: 'Duplicate Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  RESET_BLOCK_CLICKED: {
    name: 'Reset Block Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  EXPORT_METRIC_CSV_CLICKED: {
    name: 'Export Metric Data To CSV Clicked',
    category: {
      category: 'Engagement - Workspaces',
    },
  },
  SAVE_AS_IMAGE_CLICKED: {
    name: 'Save As Image Clicked',
    category: 'Engagement - Workspaces',
  },
  ADD_NEW_WORKSPACE_CLICKED: {
    name: 'Add New Workspace Clicked',
    category: 'Engagement - Workspaces',
  },
  RENAME_WORKSPACE_CLICKED: {
    name: 'Rename Workspace Clicked',
    category: 'Engagement - Workspaces',
  },
  WORKSPACE_CLICKED: {
    name: 'Workspace Clicked',
    category: 'Engagement - Workspaces',
  },
  GOOGLE_SSO_CLICKED: {
    name: 'Google SSO Clicked',
    category: 'Signup',
  },
  MICROSOFT_SSO_CLICKED: {
    name: 'Microsoft SSO Clicked',
    category: 'Signup',
  },
  APP_LOGIN_PAGE_VIEWED: {
    name: 'App Login Page Viewed',
    category: 'Signup',
  },

  ARCADE_CLICKED: {
    name: 'Arcade Clicked',
    category: 'Signup',
  },
  MMM_ORG_LETS_GO_CLICKED: {
    name: 'MMM Org Lets Go Clicked',
    category: 'Signup',
  },
  OKTA_SSO_CLICKED: {
    name: 'Okta SSO Clicked',
    category: 'Signup',
  },
  SELECT_ORG_PAGE: {
    name: 'Select Org Page',
    category: 'Signup',
  },
  SETUP_MMM_CLICKED: {
    name: 'Setup MMM Clicked',
    category: 'Signup',
  },

  // not used
  SETUP_MSU_CLICKED: {
    name: 'Setup MSU Clicked',
    category: 'Signup',
  },
  SETUP_ORG_PAGE: {
    name: 'Setup Org Page',
    category: 'Signup',
  },
};

export const appEventsPA = {
  LOGIN_PAGE_VIEWED: {
    name: 'Login Page Viewed',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Login',
    },
  },
  LOGIN_CLICKED: {
    name: 'Login Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Login',
    },
  },
  LOGIN_SUCCESS: {
    name: 'Login Success',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Login',
    },
  },
  LOGOUT_CLICKED: {
    name: 'Logout Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  WAREHOUSE_CONNECTION_COMPLETED: {
    name: 'Warehouse Connection Completed',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  CONNECTIONS_PAGE_VIEWED: {
    name: 'Connections Page Viewed',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  DBT_DETAILS_PAGE_VIEWED: {
    name: 'Dbt_Details Page Viewed',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  DBT_REPO_DETAILS_PAGE_VIEWED: {
    name: 'Dbt Repo Details_Page Viewed',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  WAREHOUSE_DETAILS_PAGE_VIEWED: {
    name: 'Warehouse Details Page Viewed',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  SEE_DBT_DETAILS_CLICKED: {
    name: 'See DBT Details Clicked',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  SEE_GITHUB_DETAILS_CLICKED: {
    name: 'See Github Details Clicked',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  SEE_WAREHOUSE_DETAILS_CLICKED: {
    name: 'See Warehouse Details Clicked',
    properties: {
      event_category: 'Connect',
      event_action: 'Engagement - Connections',
    },
  },
  EVENTS_PAGE_VIEWED: {
    name: 'Events Page Viewed',
    properties: {
      event_category: 'Curate',
      event_action: 'Engagement - Events',
    },
  },
  CUSTOM_EVENT_CREATED: {
    name: 'Custom Event Created',
    properties: {
      event_category: 'Curate',
      event_action: 'Engagement - Events',
    },
  },
  DIMENSION_TYPE_UPDATED: {
    name: 'Dimension Type Updated',
    properties: {
      event_category: 'Curate',
      event_action: 'Engagement - Events',
    },
  },
  WORKSPACES_PAGE_VIEWED: {
    name: 'Workspaces Page Viewed',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Workspaces',
    },
  },
  WORKSPACE_PAGE_VIEWED: {
    name: 'Workspace Page Viewed',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  WORKSPACE_CREATED: {
    name: 'Workspace Created',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  WORKSPACE_DELETED: {
    name: 'Workspace Deleted',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  APPLY_FILTERS_AND_RELOAD_CLICKED: {
    name: 'Apply Filters And Reload Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  EDIT_DRAFT_CLICKED: {
    name: 'Edit Draft Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  DELETE_DRAFT_CLICKED: {
    name: 'Delete Draft Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  SAVE_TO_WORKSPACE_CLICKED: {
    name: 'Save To Workspace Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  BREADCRUMB_CLICKED: {
    name: 'Breadcrumb Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Workspaces',
    },
  },
  ADD_NEW_BLOCK_CLICKED: {
    name: 'Add New Block Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  BLOCK_MENU_CLICKED: {
    name: 'Block Menu Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  CLOSE_BLOCK_CONFIG_CLICKED: {
    name: 'Close Block Configuration Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  CONFIGURE_BLOCK_CLICKED: {
    name: 'Configure Block Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  SAVE_BLOCK_CONFIG_CLICKED: {
    name: 'Save Block Configuration Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  COPY_BLOCK_CLICKED: {
    name: 'Copy Block Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  DELETE_BLOCK_CLICKED: {
    name: 'Delete Block Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  DUPLICATE_BLOCK_CLICKED: {
    name: 'Duplicate Block Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  EXPORT_METRIC_CSV_CLICKED: {
    name: 'Export Metric Data To CSV Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  SAVE_AS_IMAGE_CLICKED: {
    name: 'Save As Image Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  ADD_NEW_WORKSPACE_CLICKED: {
    name: 'Add New Workspace Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  RENAME_WORKSPACE_CLICKED: {
    name: 'Rename Workspace Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Workspaces',
    },
  },
  WORKSPACE_CLICKED: {
    name: 'Workspace Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Workspaces',
    },
  },
  MARK_ASSET_OFFICIAL: {
    name: 'Mark Asset Official',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Assets',
    },
  },
  SHARE_WORKSPACE_CLICKED: {
    name: 'Share Workspace Clicked',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Workspaces',
    },
  },
  SHARE_WORKSPACE_SUCCESS: {
    name: 'Share Workspace Success',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Workspaces',
    },
  },
  CREATE_CLICKED: {
    name: 'Create Visualizations Clicked',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  CREATE_COHORT_CLICKED: {
    name: 'Cohort Created',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Cohorts',
    },
  },
  COHORT_CREATED_SUCCESS: {
    name: 'Cohort Created',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Cohorts',
    },
  },
  EDIT_COHORT_SUCCESS: {
    name: 'Cohort Edited',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Cohorts',
    },
  },
  COHORT_PAGE_VIEWED: {
    name: 'Cohort Page Viewed',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Cohorts',
    },
  },
  VISUALIZATION_CREATED: {
    name: 'Visualization Created',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  VISUALIZATION_RENDERED: {
    name: 'Visualization Rendered',
    properties: {
      event_category: 'Consume',
      event_action: 'Engagement - Visualization',
    },
  },
  VISUALIZATION_EDITED: {
    name: 'Visualization Edited',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  VISUALIZATION_DELETED: {
    name: 'Visualization Deleted',
    properties: {
      event_category: 'Create',
      event_action: 'Engagement - Visualization',
    },
  },
  WORKSPACE_COMMENTS_VIEWED: {
    name: 'Workspace Comments Viewed',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Workspaces',
    },
  },
  BLOCK_ANNOTATION_CREATED: {
    name: 'Block Annotation Created',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Visualization',
    },
  },
  BLOCK_COMMENT_ADD_CLICKED: {
    name: 'Block Comment Added',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Visualization',
    },
  },
  BLOCK_COMMENT_CLICKED: {
    name: 'Block Comment Clicked',
    properties: {
      event_category: 'Collaborate',
      event_action: 'Engagement - Visualization',
    },
  },
  USER_ACTIVITY_PAGE_VIEWED: {
    name: 'User ACtivity Page Viewed',
    properties: {
      event_category: 'Consume',
      event_action: 'Engagement - Admin',
    },
  },
  ADMIN_PAGE_VIEWED: {
    name: 'Admin Page Viewed',
    properties: {
      event_category: 'Control',
      event_action: 'Engagement - Admin',
    },
  },
  ADMIN_CLICKED: {
    name: 'Admin Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  USER_ACTIVITY_CLICKED: {
    name: 'User Activity Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  TEMPLATES_CLICKED: {
    name: 'Templates Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  GOALS_CLICKED: {
    name: 'Goals Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  METRICS_CLICKED: {
    name: 'My Metrics Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  ORG_IMAGE_CLICKED: {
    name: 'Org Image Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
  SIDEBAR_WORKSPACES_CLICKED: {
    name: 'Sidebar Workspaces Clicked',
    properties: {
      event_category: 'General',
      event_action: 'Engagement - Sidebar',
    },
  },
};

export function getPaFormattedEvent(
  appEvent: AnalyticsEventPA,
  props?: Record<string, any>
) {
  const RUDDER_ANALYTIC_EVENT = appEvent;
  RUDDER_ANALYTIC_EVENT.properties = {
    ...RUDDER_ANALYTIC_EVENT.properties,
    ...props,
  };
  return RUDDER_ANALYTIC_EVENT;
}

export function getFormattedEvent(
  appEvent: AnalyticsEvent,
  block_type: string,
  visualization_type?: string
) {
  const ANALYTIC_EVENT: AnalyticsEvent = appEvent;
  ANALYTIC_EVENT.category.block_type = block_type;
  if (block_type === 'visualization') {
    ANALYTIC_EVENT.category.visualization_type = visualization_type;
  } else {
    delete ANALYTIC_EVENT.category.visualization_type;
  }
  return ANALYTIC_EVENT;
}

export default appEvents;
